<template>

</template>

<script>
export default {
  name: "linkredirect",
  props: ['link'],

  mounted() {

    var getLink = localStorage.getItem('last_link');
    if (getLink){
      localStorage.removeItem('last_link');
      this.$router.push('/');
    }else {
      localStorage.setItem('last_link', this.link);
      location.href = this.link;


    }




  }
}
</script>

<style scoped>

</style>