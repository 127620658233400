<template>
  <div class="rate-wrap">
    <div class="container">
      <div class="g-head" data-aos="zoom-in" data-aos-duration="500" data-aos-delay="200">
        <h3>{{ strings.title_c }}</h3>
        <p>{{ strings.desc_c }}</p>
      </div>
      <div class="row">


        <div class="block col-md-4 col-xs-4 aos-init aos-animate active" data-aos="zoom-in" data-aos-duration="500"
             data-aos-delay="400">
          <div class="inner" @click="showRate(1)" data-toggle="modal" data-target="#rate_pop">
            <img :src="sad_img" alt="">
            <div>
              <img :src="star_svg" alt="" class="not-filled">
              <img :src="star_filed" alt="" class="filled">
            </div>
          </div>
        </div>

        <div class="block col-md-4 col-xs-4" data-aos="zoom-in" data-aos-duration="500" data-aos-delay="600">
          <div class="inner" @click="showRate(2)">
            <img :src="sad_img" alt="">
            <div>
              <img :src="star_svg" alt="" class="not-filled">
              <img :src="star_svg" alt="" class="not-filled">
              <img :src="star_filed" alt="" class="filled">
              <img :src="star_filed" alt="" class="filled">
            </div>
          </div>
        </div>
        <div class="block col-md-4 col-xs-4" data-aos="zoom-in" data-aos-duration="500" data-aos-delay="800">
          <div class="inner" @click="showRate(3)">
            <img :src="sad_img" alt="">
            <div>
              <img :src="star_svg" alt="" class="not-filled">
              <img :src="star_svg" alt="" class="not-filled">
              <img :src="star_svg" alt="" class="not-filled">
              <img :src="star_filed" alt="" class="filled">
              <img :src="star_filed" alt="" class="filled">
              <img :src="star_filed" alt="" class="filled">
            </div>
          </div>
        </div>

        <div class="block col-md-4 col-xs-4" data-aos="zoom-in" data-aos-duration="500" data-aos-delay="200">
          <div class="inner" @click="showRate(4)">
            <img :src="meh" alt="">
            <div>
              <img :src="star_svg" alt="" class="not-filled">
              <img :src="star_svg" alt="" class="not-filled">
              <img :src="star_svg" alt="" class="not-filled">
              <img :src="star_svg" alt="" class="not-filled">
              <img :src="star_filed" alt="" class="filled">
              <img :src="star_filed" alt="" class="filled">
              <img :src="star_filed" alt="" class="filled">
              <img :src="star_filed" alt="" class="filled">
            </div>
          </div>
        </div>
        <div class="block col-md-4 col-xs-4">

        </div>
        <div class="block col-md-4 col-xs-4" data-aos="zoom-in" data-aos-duration="500" data-aos-delay="400">
          <div class="inner" @click="showRate(5)">
            <img :src="smile" alt="">
            <div>
              <img :src="star_svg" alt="" class="not-filled">
              <img :src="star_svg" alt="" class="not-filled">
              <img :src="star_svg" alt="" class="not-filled">
              <img :src="star_svg" alt="" class="not-filled">
              <img :src="star_svg" alt="" class="not-filled">
              <img :src="star_filed" alt="" class="filled">
              <img :src="star_filed" alt="" class="filled">
              <img :src="star_filed" alt="" class="filled">
              <img :src="star_filed" alt="" class="filled">
              <img :src="star_filed" alt="" class="filled">
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="thank-txt">
        <p>Thank you!</p>
    </div> -->
  </div>
  <div class="modal fade" id="rate_pop">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body">
          <div class="subs-wrap">
            <div class="g-head" data-aos="zoom-in" data-aos-duration="500" data-aos-delay="200">
              <h3>{{ strings.form_title }}</h3>
              <p>{{ strings.form_desc }}</p>
            </div>
            <div class="cardo">
              <div class="form-group">
                <input type="text" class="form-control" v-model="your_name" :placeholder="$t('Full Name:')">
              </div>
              <div class="form-group">
                <input type="email" required class="form-control" v-model="your_email" :placeholder="$t('Email:')">
              </div>
              <div class="form-group">
                <input type="text" class="form-control" v-model="your_phone" :placeholder="$t('Mobile:')">
              </div>
              <div class="form-group">
                <textarea class="form-control" v-model="your_message" :placeholder="$t('Comments:')"></textarea>
              </div>
              <div class="form-group">
                <button type="submit" @click="sendRate" class="btn">{{ $t('submit') }}</button>
              </div>
            </div>
          </div>
        </div>
      </div><!-- /.modal-content -->
    </div><!-- /.modal-dialog -->
  </div><!-- /.modal -->

</template>

<script>
import Notiflix from "notiflix";

export default {
  name: "rate",
  props: ["form_id"],

  data() {
    return {
      form_link:'',
      sad_img: require("@/assets/images/sad.svg"),
      star_svg: require("@/assets/images/star.svg"),
      star_filed: require("@/assets/images/star-filled.svg"),
      meh: require("@/assets/images/meh.svg"),
      smile: require("@/assets/images/smile.svg"),
      stars: 1,
      your_name: '',
      your_phone: '',
      your_message: '',
      your_email: '',
      strings: {
        title_c: '',
        desc_c: '',
        form_title: '',
        form_desc: ''
      }
    };
  }, mounted() {
    $('.rate-wrap .block').click(function () {
      $(this).addClass('active').siblings().removeClass('active');
      // $('.thank-txt').addClass('active');
    });
  },
  methods: {
    creatformlink: function () {
      return this.siteurl + '/wp-json/contact-form-7/v1/contact-forms/' + this.form_id + '/feedback'
    },
    showRate: function (stars = 1) {
      this.stars = stars;
      $('#rate_pop').modal('show');
    },
    sendRate: function () {

      Notiflix.Block.circle('.cardo');
      var bodyFormData = new FormData();
      bodyFormData.append('your_name', this.your_name);
      bodyFormData.append('your_email', this.your_email);
       bodyFormData.append('your_phone', this.your_phone);
      bodyFormData.append('your_message', this.your_message);
      bodyFormData.append('stars', this.stars);

      axios({
        method: "post",
        url: this.form_link,
        data: bodyFormData,
        headers: {"Content-Type": "multipart/form-data"},
      }).then((res) => {
        //Perform Success Action

        if (res.data.status == "mail_sent") {
          Notiflix.Report.success(this.$t('Message Sent Successfully'), res.data.message, this.$t('okay'));

        } else {
          Notiflix.Report.failure('error', res.data.message, this.$t('okay'));

        }
      })
          .catch((error) => {
            // error.response.status Check status code
           }).finally(() => {
        //Perform action in always
      });
      Notiflix.Block.remove('.cardo', 900);


    },
    GetStrings: function () {

      axios.get('get_strings', {

        params: {
          fields: 'title_c,desc_c,form_title,form_desc'
        },
      }).then((response) => {
        if (response.status == 200) {
           if (response.data) {
            this.strings.title_c = response.data.title_c;
            this.strings.desc_c = response.data.desc_c;
            this.strings.form_title = response.data.form_title;
            this.strings.form_desc = response.data.form_desc;
          }
        }
      });

    }

  }, created() {
    this.GetStrings();
   this.form_link = this.creatformlink();
  }
}
</script>

<style scoped>

</style>