// Constants
import {createApp} from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";

window.axios = require("axios");
global.jQuery = require("jquery");
var $ = global.jQuery;
window.$ = $;

require("bootstrap/dist/js/bootstrap.min");
require("@/assets/js/owl.carousel.min");
/*
require("@/assets/js/sellect2");
*/
import AOS from "aos/dist/aos";

import Notiflix, { Loading} from "notiflix";

window.Notiflix = Notiflix;

$(function () {
    AOS.init();
});
window.addEventListener("load", AOS.refresh);

// import library
import SC_fonts from "@/assets/css/fonts.css";
import SC_bootstrap from "bootstrap/dist/css/bootstrap.min.css";
import SC_bootstrapgrid from "bootstrap/dist/css/bootstrap-grid.min.css";
import SC_Aos from "@/assets/css/aos.css";
import owl_style from "@/assets/css/owl.carousel.min.css";
import SC_style from "@/assets/css/style.css";
import SC_styleresponsive from "@/assets/css/style-res.css";
import i18n from "./i18n";
/*
import sellect2style from "@/assets/css/sellect2.css";
*/

// set lang
router.beforeEach((to, from, next) => {

    // use the language from the routing param or default language
    let language = to.params.lang;
    if (!language) {
        language = 'en'
    }

    // set the current language for i18n.
    i18n.locale = language
    next()
})
Notiflix.Loading.init({ svgColor:"#c72b86", });
axios.defaults.baseURL = `https://3alammash.com/strlink/wp-json/appsettings/v1`;
router.beforeEach((to, from, next) => {
    Notiflix.Loading.pulse();

    next()
})
router.afterEach(() => {
    Notiflix.Loading.pulse();
    Notiflix.Loading.remove(1500);
})

//uses
var the_app = createApp(App)
the_app.use(i18n)
/*
the_app.use(sellect2style)
*/
the_app.use(owl_style)
    .use(SC_fonts)
    .use(SC_bootstrap)
    .use(SC_bootstrapgrid)
    .use(SC_Aos)
    .use(SC_style)
    .use(SC_styleresponsive)
    /// JS
    .use(AOS)
    .use(store)
    .use(router)
    .config.globalProperties.siteurl = 'https://3alammash.com/strlink';
the_app.mount("#app");


