<template>
  <div class="box-wrap" v-if="type == 'brochure'">
    <burchoure :iframelink="blockData.brochure_link"></burchoure>
  </div>
  <div v-else-if="type == 'subscribe'">
    <subscribe :form_id="form_id"/>
  </div>
  <div v-else-if="type =='rate'">
    <rate :form_id="form_id"/>
  </div>
  <div v-else-if="type =='branches'">
    <Branches/>
  </div>
  <div v-else-if="type =='social'">
    <social/>
  </div>
  <div v-else-if="type == 'link'">
    <linkredirect :link="block_link"/>
  </div>
  <div v-else-if="type == 'html'" v-html="content"></div>

</template>
<script>
import Burchoure from "@/components/burchoure";
import Subscribe from "@/components/subscribe";
import Rate from "@/components/rate";
import Branches from "@/components/Branches";
import Social from "@/components/social";
import Linkredirect from "@/components/linkredirect";

export default {
  name: "innerpages",
  components: {Linkredirect, Social, Branches, Rate, Subscribe, Burchoure},
  data() {
    return {
      block_id: this.$route.params.page_id,
      type: "",
      name: "",
      blockData: "",
      content: "",
      form_id: "",
      block_link:''
    };
  },
  methods: {
    GetBlockDetail: function () {
      axios.get("/block/" + this.block_id).then((response) => {
        if (response.status == 200 && response.data) {
          if (response.data.id) {
            this.blockData = response.data;
            this.type = response.data.type;
            this.name = response.data.name;
            if (response.data.type == "html") {
              this.content = response.data.content;
            }
            if (response.data.form_id) {
              this.form_id = response.data.form_id;
            }
            if (response.data.block_link){
              this.block_link = response.data.block_link;
            }
          }
        }
      });
    },
  },
  mounted() {
    this.GetBlockDetail();
  },

  watch: {
    "$route.params.id"(val) {
      // call the method which loads your initial state
      this.find();
      console.log(this.find());
      console.log(val);
    },
  },
};
</script>

<style scoped></style>
