<template>
  <div v-if="block_style" class="block col-md-4 col-sm-6 col-xs-6 spec-card" data-aos="zoom-in" data-aos-duration="500" data-aos-delay="200">
    <div class="inner">
      <router-link :to="{ name: 'innerpages', params: { page_id: id } }">
        <div class="a-text">
          <span>{{ name }}</span>
          <h4>{{ sub_title }}</h4>
        </div>
        <img :src="small_icon" alt="" class="normal-shadow"/>
       </router-link>
    </div>
  </div>

  <div v-else
      class="block col-md-4 col-sm-6 col-xs-6"
      data-aos="zoom-in"
      data-aos-duration="500"
      :data-aos-delay="the_dealy"
      :data_index="index">
    <div class="inner">
      <router-link :to="{ name: 'innerpages', params: { page_id: id } }">
        <div class="a-text">
          <span>{{ name }}</span>
          <h4>{{ sub_title }}</h4>
        </div>
        <img :src="small_icon" alt="" class="arrow-l"/>
        <img :src="img" alt="" class="op-shadow"/>
      </router-link>
    </div>
  </div>

</template>

<script>
export default {
  name: "block",
  data() {
    return {
      the_dealy: this.dealy,
      the_index: this.index,
    };
  },
  props: [
    "name",
    "sub_title",
    "id",
    "small_icon",
    "type",
    "img",
    "index",
    "dealy",
    "content",
    "block_style",
  ],
  methods: {
    UpdateDealy: function () {
      var the_index = this.the_index + 1;
      var dealy_data = this.the_dealy;
      if (the_index) {
        for (let i = 0; i < the_index; i++) {
          dealy_data += 100;
        }
      }
      this.the_dealy = dealy_data;
    },
  },
  mounted() {
    this.UpdateDealy();
  },
};
</script>

<style scoped></style>
