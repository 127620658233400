import {createRouter, createWebHistory} from "vue-router";
import Home from "../views/Home.vue";
import innerpages from "@/views/innerpages";
import i18n from "@/i18n";

const routes = [
    {
        path: '/',

        redirect: `/${i18n.global.locale}`

    },
    {
        path: '/:lang',
        component: Home,
        children: [
            {
                path: "page/:page_id",
                name: "innerpages",
                component: innerpages,
            }
        ],
        name:'Home'
     }
];


const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior (to, from, savedPosition) {
        window.scrollTo(0,1);

    }
});


export default router;
