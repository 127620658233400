<template>
  <div class="box-extra">
    <div class="container">
      <div>
        <h4 v-text="follow_us_text"></h4>
        <div
          data-aos="fade-up"
          v-if="scoial_links"
          data-aos-duration="500"
          data-aos-delay="200"
        >
          <a
            target="_blank"
            :href="soclink.link"
            v-for="soclink in scoial_links"
          >
            <img :src="soclink.icon" alt="" />
          </a>
        </div>
      </div>
      <div
        data-aos="fade-up"
        v-if="appstore"
        data-aos-duration="500"
        data-aos-delay="400"
      >
        <h4>Download Starlink Qatar Mobile App</h4>
        <div>
          <span v-for="app in appstore">
            <img :src="app.qr_code" alt="" />
            <a :href="app.link">
              <img :src="app.store_logo" alt="" />
            </a>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "social",
  data() {
    return {
      follow_us_text: null,
      scoial_links: "",
      appstore: "",
    };
  },
  methods: {
    GetDatasocial: function () {
      axios.get("/social").then((response) => {
        if (response.status == 200 && response.data) {
          this.follow_us_text = response.data.follow_us_text;
          this.scoial_links = response.data.scoial_links;
          this.appstore = response.data.appstore;
        }
      });
    },
  },
  mounted() {
    this.GetDatasocial();
  },
};
</script>

<style scoped></style>
