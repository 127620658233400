<template>
  <div class="social-wrap">
    <div class="container">
      <div class="g-head" data-aos="zoom-in" data-aos-duration="500" data-aos-delay="200">
        <h3>Social Media</h3>
        <p>Scan QR Code to landing page </p>
      </div>
      <div class="row">

        <div v-if="socials" v-for="soc in socials" class="block col-md-3 col-sm-6 col-xs-6" data-aos="zoom-in" data-aos-duration="500" data-aos-delay="400">
          <div class="inner">
            <a href="javascript:void(0);"></a>
            <img :src="soc.icon" alt="">
            <img :src="soc.qr_code" alt="" class="qr-code">
          </div>
        </div>

      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "social",
  data() {
    return {
      socials: ''
    }
  },
  methods: {
    GetSocials: function () {
      axios.get('/socials').then((response) => {
        if (response.status == 200 && response.data) {
          this.socials = response.data;
        }
      });
    }
  },
  created() {
    this.GetSocials();
  }
}
</script>

<style scoped>

</style>