<template>
  <div class="subs-wrap">
    <div class="container">
      <div class="g-head" data-aos="zoom-in" data-aos-duration="500" data-aos-delay="200">
        <h3>{{ $t('Subscribe') }}</h3>
        <p>{{ $t('desc_subscribe') }}</p>
      </div>
      <div class="cardo">

        <div class="form-group">
          <input type="text" class="form-control" required v-model="formdata.name" :placeholder="$t('Full Name:')">
        </div>
        <div class="form-group">
          <input type="text" class="form-control" required v-model="formdata.mobile" :placeholder="$t('Mobile:')">
        </div>
        <div class="form-group">
          <input type="email" class="form-control" required v-model="formdata.email" :placeholder="$t('Email:')">
        </div>
        <div class="form-group">
          <input type="text" class="form-control" v-model="formdata.adress" :placeholder="$t('Address:')">
        </div>
        <div class="form-group">
          <textarea class="form-control" v-model="formdata.message" :placeholder="$t('Comments:')"></textarea>
        </div>
        <div class="form-group">
          <button @click="SendForm" type="submit" class="btn">{{ $t('submit') }}</button>
        </div>

      </div>
    </div>
  </div>

</template>

<script>
import Notiflix, {Block, Loading, Confirm, Report} from "notiflix";

export default {
  name: "subscribe",
  props: ["form_id"],
  data() {
    return {
      form_link: "",
      formdata: {
        name: "",
        mobile: "",
        adress: "",
        email: "",
        message: ""
      }


    }
  },
  methods: {
    creatformlink: function () {
      return this.siteurl + '/wp-json/contact-form-7/v1/contact-forms/' + this.form_id + '/feedback'
    },
    SendForm: function () {
      Notiflix.Block.circle('.cardo');
      var bodyFormData = new FormData();
      bodyFormData.append('name', this.formdata.name);
      bodyFormData.append('email', this.formdata.email);
      bodyFormData.append('adress', this.formdata.adress);
      bodyFormData.append('mobile', this.formdata.mobile);
      bodyFormData.append('message', this.formdata.message);

      axios({
        method: "post",
        url: this.form_link,
        data: bodyFormData,
        headers: {"Content-Type": "multipart/form-data"},
      }).then((res) => {
        //Perform Success Action

        if (res.data.status == "mail_sent") {
          Notiflix.Report.success(this.$t('Message Sent Successfully'), res.data.message, this.$t('okay'));

         } else {
          Notiflix.Report.failure('error', res.data.message, this.$t('okay'));

        }
      })
          .catch((error) => {
            // error.response.status Check status code
            console.log(error)
          }).finally(() => {
        //Perform action in always
      });
      Notiflix.Block.remove('.cardo', 900);

    }
  },
  created() {
    this.form_link = this.creatformlink();
  }

}
</script>

<style scoped>

</style>