<template>
  <header class="main-head">
    <div class="container">
      <a href="javascript:void(0)" class="op-menu btn-circle">
        <img :src="menu_icon" alt=""/>
      </a>
      <div class="locale-changer">
        <select v-model="$root.$i18n.locale" class="form-control">
          <option v-for="(lang, i) in langs" :key="`Lang${i}`" :value="lang">
            {{ lang }}
          </option>
        </select>
      </div>
      <add-to-home-btn/>

      <div class="logo">
        <router-link :to="`/${$i18n.locale}`">
          <img :src="logo" alt=""/>
        </router-link>
      </div>
      <router-link :to="`/${$i18n.locale}`" class="btn-circle">
        <img :src="home_icon" alt=""/>
      </router-link>
    </div>
  </header>

</template>

<script>
import i18n from "@/i18n";
import AddToHomeBtn from "@/components/global/addToHomeBtn";

export default {
  name: "Header",
  components: {AddToHomeBtn},
  data() {
    return {
      menu_icon: require("@/assets/images/menu-bars.svg"),
      logo: require("@/assets/images/logo.png"),
      home_icon: require("@/assets/images/home.svg"),
      langs: ['en', 'ar'],
      elang: ''
    };
  },
  methods: {
    GetOtherLang: function () {
      var the_lang = '';
      if (i18n.locale == 'ar') {
        the_lang = 'en';
      } else {
        the_lang = 'ar'
      }
      this.elang = the_lang;
      i18n.locale = the_lang;
     }
  },
  mounted() {
    this.GetOtherLang();
  }
};
</script>

<style scoped></style>
