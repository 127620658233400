{
  "message": "hello i18n !!",
  "lang": "العربية",
  "submit": "submit",
  "Full Name:": "Full Name:",
  "Mobile:": "Mobile:",
  "Email:": "Email:",
  "Address:": "Address:",
  "Comments:": "Comments:",
  "Subscribe": "Subscribe",
  "desc_subscribe": "Be the First to know about our Latest Updates and Special Offers!",
  "Message Sent Successfully": "Message Sent Successfully",
  "okay": "Okay",
  "zoom": "zoom",
  "Address": "Address",
  "Select Branch": "Select Branch",
  "Branches Map": "Branches Map",
  "Social Media": "Social Media",
  "Scan QR Code to landing page": "Scan QR Code to landing page",
  "Loading...": "Loading..."

}