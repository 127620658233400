<template>
  <div class="iframe__ cardo" id="the_iframe">
    <iframe
      :src="iframelink"
      frameborder="0"
      style="overflow: hidden; height: 100%; width: 100%"
      height="100%"
      width="100%"
    ></iframe>
  </div>
</template>

<script>
import Notiflix from "notiflix";

export default {
  name: "burchoure",
  props: ["iframelink"],
  methods: {
    scrollTo: function (id) {
      $("html,body").animate(
        {
          scrollTop: $("#" + id).offset().top - $(window).height() / 2,
        },
        1000
      );
    },
  },
  mounted() {
    Notiflix.Block.standard('.cardo', this.$t('Loading...'));
    Notiflix.Block.remove('.cardo', 5000);

  },
};
</script>

<style scoped>
.iframe__ {
  height: 100vh;
}
@media only screen and (max-width: 600px) {
  .iframe__ {
    height: unset !important;
  }
}
</style>
