<template>
  <div class="wrapper col-xs-12">
    <Header/>
    <Footer/>

    <main class="main-content">
      <div v-if="$route.params.page_id">
        <innerpages :key="$route.params.page_id"/>
      </div>
      <div v-else="">
        <router-view></router-view>
      </div>
      <social v-if="$route.name == 'Home'"/>
      <keep-alive>
        <slider/>
      </keep-alive>
    </main>
  </div>

</template>
<script>
// @ is an alias to /src

import Header from "@/components/global/Header";
import Footer from "@/components/global/Footer";
import slider from "@/components/slider";
import social from "@/components/global/social";
import Innerpages from "@/views/innerpages";

export default {
  name: "App",
  components: {
    Innerpages,
    Footer,
    Header,
    slider,
    social,
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  }
};
</script>
