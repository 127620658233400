{
  "message": "hello i18n !!",
  "lang": "العربية",
  "submit": "ارسال",
  "Full Name:": "الاسم كامل:",
  "Mobile:": "الهاتف:",
  "Email:": "Email:",
  "Address:": "Address:",
  "Comments:": "Comments:",
  "Subscribe": "Subscribe",
  "desc_subscribe": "Be the First to know about our Latest Updates and Special Offers!"
}