<template>
  <div class="box-footer">
    <div class="container">
      <div
          v-if="slider_data"
          id="the_slider"
          class="footer-slider owl-carousel"
      >
        <div class="item" v-for="slide in slider_data">
          <a :href="slide.link">
            <img :src="slide.img" alt=""/>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "slider",
  data() {
    return {
      slider_data: "",
    };
  },
  methods: {
    GetSliders: function () {
      axios.get("/slider").then((response) => {
        if (response.data) {
          this.slider_data = response.data;
        }
      });
    },
  },
  created() {
    this.GetSliders();

  },
  mounted() {


    document.onreadystatechange = () => {
      if (document.readyState == "complete") {

        setTimeout(function () {
          $(".footer-slider").owlCarousel({
            nav: false,
            loop: false,
            dots: true,
            autoplay: 5000,
            center: true,
            autoplaySpeed: 1000,
            autoplayHoverPause: true,
            items: 1,
            navText: [
              "<i class='la la-angle-left'></i>",
              "<i class='la la-angle-right'></i>",
            ],
            animateIn: "fadeIn",
            animateOut: "fadeOut",
          });

        }, 3000);
      }
    }
  },
  beforeMount() {

  },
};
</script>

<style scoped></style>
