<template>
  <div class="side-menu">
    <button type="button" class="cl-menu">
      <img :src="close_icon" alt="" />
    </button>
    <ul v-if="menu_items">
      <li v-for="(item, index) in menu_items">
        <router-link :to="{ name: 'innerpages', params: { page_id: item.id ,lang:'en'} }">
          <span>{{ item.name }}</span> {{ item.sub_title }}
        </router-link>
      </li>
    </ul>
  </div>
  <div class="overlay-s"></div>
</template>

<script>
import AOS from "aos";

export default {
  name: "Footer",
  data() {
    return {
      close_icon: require("@/assets/images/close.svg"),
      menu_items: "",
    };
  },
  methods: {
    GetMenu: function () {
      axios.get("/sidemenu").then((response) => {
        if (response.status == 200 && response.data) {
          this.menu_items = response.data;
        }
      });
    },
  },
  created() {
    this.GetMenu();
  },
  mounted() {
    AOS.init({
      once: true,
    });

    $(".op-menu").click(function () {
      $(".side-menu, .overlay-s").addClass("active");
      $("html").addClass("off");
    });

    $(".cl-menu, .overlay-s").click(function () {
      $(".side-menu, .overlay-s").removeClass("active");
      $("html").removeClass("off");
    });
  },
};
</script>

<style scoped></style>
