<template>
  <div class="box-wrap">
    <div v-if="blocks" class="container">
      <block
          v-for="(block, index) in blocks"
          :name="block.name"
          :small_icon="block.small_icon"
          :sub_title="block.sub_title"
          :type="block.type"
          :index="index"
          :dealy="200"
          :img="block.img"
          :content="block.content"
          :id="block.id"
          :block_style="block.block_style">
      </block>

    </div>
  </div>
</template>

<script>
// @ is an alias to /src

import Block from "@/components/block";

export default {
  name: "Home",
  components: {
    Block,
  },
  data() {
    return {
      blocks: "",
      evex: "sss",
    };
  },
  methods: {
    GetBlocks: function () {
      axios.get("/homeblocks").then((response) => {
        if (response.status == 200) {
          if (response.data) {
            this.blocks = response.data;
          }
        }
      });
    },
  },
  mounted() {
    this.GetBlocks();
  },
};
</script>
